<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent :title="`등록환자 정보 ${mode_text}`" />
      </v-col>
    </v-row>

    <v-row>
      <v-btn class="mx-4" @click="onClickCopy">
        복사하기
      </v-btn>
    </v-row>
    <v-row v-show="mode !== 'add'">
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-tabs
          v-model="tabModel"
          centered
          grow
          background-color="indigo lighten-4"
          slider-color="indigo darken-3"
        >
          <v-tab key="tab1">
            등록환자 정보
          </v-tab>
          <v-tab key="tab2">
            매칭 정보
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-tabs-items v-model="tabModel">
          <v-tab-item
            key="tab1"
            class="pb-8"
            style="background: #F3F3F3;"
          >
            <PatientProfileComponent
              v-model="model"
              :location_idx="model.formItem.location_idx"
              :location_detail_idx="model.formItem.location_detail_idx"
            />
          </v-tab-item>
          <v-tab-item
            key="tab2"
            class="pb-8"
            style="background: #F3F3F3;"
          >
            <MatchInfoComponent
              v-model="model"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-5"
    >
      <v-col
        cols="auto"
      >
        <v-btn
          color=""
          class="mx-4"
          @click="backToListPage"
        >
          <v-icon small>
            list
          </v-icon>
          <span class="pl-2">목록으로</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Patient from './model/patient'
import MatchInfoComponent from './MatchInfo'
import PatientProfileComponent from './PatientProfile'
import PageTitleComponent from '../_elements/title/PageTitle'
import { Api } from '../../libraries/api'
import { getAddressList } from '../address/model/addressFinder'
import modelApi from './model/api'

export default {
  components: {
    PageTitleComponent,
    PatientProfileComponent,
    MatchInfoComponent
  },
  data() {
    return {
      model: new Patient(),
      patientIdx: null,
      tabModel: 'tab1',
      mode: '',
      mode_text: ''
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      const { idx } = this.$route.params
      if (idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.patientIdx = parseInt(idx, 10)
        this.model.idx = this.patientIdx
        await this.model.bindItem()
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Patients.List' || latestHistory.name === 'Process.List') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/patients?page=1`)
      }
    },
    async onClickCopy() {
      const importantThingItems = await modelApi.getImportThingItems()
      const regions = await getAddressList()

      const { idx } = this.$route.params
      const { name, phone, age, gender } = this.model.item.basic_info
      const { created_at, started_at, ended_at,want_carer_gender, important_things_to_pick_career_idx, medical_information, care_time_info, location_type_detail_1, location_idx, daily_payment } = this.model.item.extra_info
      
      const importantThing = importantThingItems.find(v => v.idx === important_things_to_pick_career_idx)
      const region = regions.find(v => v.idx === location_idx)

      // 시작시간
      // 선정가격
      // 환자 병명

      let wantCarerGender = '무관'
      if (want_carer_gender === 'male') {
        wantCarerGender = '남성'
      } else if (want_carer_gender === 'female') {
        wantCarerGender = '여성'
      }

      const value = [
        idx, name, created_at.split('T')[0], '', '','', phone, age, gender === 'male' ? '남성' : '여성', '','',wantCarerGender, importantThing.reason, daily_payment, '', started_at, ended_at, care_time_info, location_type_detail_1, region.short_name, medical_information,
      ].map(v => `"${v}"`).join('\t')

      navigator.clipboard.writeText(value)
      this.$bus.$emit('successMessage', '복사완료')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
